import React, { useEffect, useState } from "react";
import {
  Avatar, 
  Box,
  Button,
  Grid,
  Link,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";
import { Auth } from "aws-amplify";
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import SignIn from './SignIn';
import Alert from './components/snackbar-message';
import { setRequireMFA } from './MFA-setup-slice';
import { useDispatch } from 'react-redux';
import { version as app_version } from './version';
import Copyright from "./copyright";

const theme_blue = {
  light: "#ffffff",
  main: "#2c2c34", //changed
  dark: "#3c3c44",
  contrastText: "#44d3b4", //changed
};

const theme_black = {
  light: "#2c2c2c",
  main: "#000000",
  dark: "#000000",
  contrastText: "#ffffff",
};

const theme_orange = {
  light: "#ff935f",
  main: "#fc6132",
  dark: "#c22d01",
  contrastText: "#FFFFFF",
};

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: theme_blue,
    secondary: theme_black,
    orange: theme_orange,
  },
});
  
const CustomSignUp = ({group}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patientAddress, setPatientAddress] = useState("");
  const [patientPhone, setPatientPhone] = useState("");
  const [patientPreferred, setPatientPreferred] = useState('email');
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [NPI, setNPI] = useState("");
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [code, setCode] = useState("");
  const [ completeSignupRequest, setCompleteSignupRequest ] = useState(false);
  const [isDoctor, setIsDoctor] = useState(false);
  const [help, setHelp] = useState(false);
  const [open, setOpen] = useState(false);
  const [helpEmail, setHelpEmail] = useState(null);
  const [helpNPI, setHelpNPI] = useState("");
  const [helpMessage, setHelpMessage] = useState("");
  const [codeResent, setCodeResent] = useState(false);
  const [helpSent, setHelpSent] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [npiError, setNpiError] = useState(false);
  const [error, setError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [helpError, setHelpError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [codeErrorText, setCodeErrorText] = useState('');
  const [npiErrorText, setNpiErrorText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [helpErrorText, setHelpErrorText] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const passwordErrorText = "Your password must be at least 8 characters long";
  const nameErrorText = "Name is required";
  const dispatch = useDispatch();
  const apiUrl = process.env.REACT_APP_API_URL;

  const AWS = require('aws-sdk');
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_REGION,
  });
  const cognito = new AWS.CognitoIdentityServiceProvider();

  useEffect(() => {
    console.log(group)
    if(group == "Unconfirmed") {
      setIsDoctor(true);
    }
  }, []);

  useEffect(() => {
    if(!completeSignupRequest){
      return;
    }

    setCodeResent(false);
    setCodeError(false);
    setNpiError(false);
    setError(false);
    setPasswordError(false);

    console.log(group)
    let isPatient = true;
    let signUpName = name;
    let signUpAddress = location;

    if(group !== "Patient") {
      isPatient = false;
    }
    else {
      signUpName = patientName;
      signUpAddress = patientAddress;
    }

    const confirmSignUp = async () => {
      console.log(isPatient, signUpName, signUpAddress, NPI, patientPhone, patientPreferred)
      const response = await fetch(`${apiUrl}/users/${completeSignupRequest.email}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          isPatient: isPatient.toString(),
          name: signUpName,
          location: signUpAddress,
          npi: NPI,
          phone: patientPhone,
          preferredMethod: patientPreferred
        }),
      });
      console.log(response);
      setCompleteSignupRequest(null);
    }

    if(code) {
      const fetchData = async () => {
        Auth.confirmSignUp(completeSignupRequest.email, completeSignupRequest.code)
          .then(() => {
            setCode("");
            console.log("SignUp Complete")
            confirmSignUp().then(() => {
              signIn();
            });
          })
          .catch((e) => {
            setCodeError(true);
            setCodeErrorText("Error: Incorrect Code");
          })
      }
      fetchData();
    } 
    else {
      setCodeError(true);
      setCodeErrorText("Error: Please enter a code");
    }
  }, [completeSignupRequest]);

  const lookupNPI = async () => {
    const npiResponse = await fetch(`${apiUrl}/npi/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({npi: NPI}),
    })
    return npiResponse.json();
  }

  const checkNPI = (e)=> {
    setCodeResent(false);
    setCodeError(false);
    setNpiError(false);
    setError(false);
    setPasswordError(false);
    e.preventDefault();
    console.log(NPI)
    const len = Math.ceil(Math.log10(NPI));
    if (len === 10){
      lookupNPI()
        .then((result) => {
          if(result.length === 1) {
            setName(result[0].name);
            setLocation(result[0].location);
            signUp();
            return true;
          }
          else{
            setNpiError(true);
            setNpiErrorText("Error: Invalid NPI");
            setHelp(true);
            return false;
          }
        });
    }
    else{
      setNpiError(true);
      setNpiErrorText("Error: Invalid NPI");
      setHelp(true);
      return false;
    }
  }

  const checkPatientFields = (e) => {
    setCodeResent(false);
    setCodeError(false);
    setError(false);
    setPasswordError(false);
    setEmailError(false);
    setNameError(false);
    e.preventDefault();

    if(patientName && email && password.length >= 8) {
      signUp();
    }
    else {
      if(!patientName) {
        setNameError(true);
      }
      if(!email) {
        setEmailErrorText("Email is required");
        setEmailError(true);
      }
      if(password.length < 8) { 
        setPasswordError(true);
      }
    } 
  }

  async function checkUser() {
    cognito.adminGetUser({
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      Username: email,
    }).promise().then((result) => {
      let accountStatus = result.UserStatus;
      console.log(accountStatus)
      if(accountStatus === "CONFIRMED") {
        setError(true);
        setErrorText("Error: User already exists, please return to sign in");
      }
      else if(accountStatus === "UNCONFIRMED") {
        //delete account and try to re-sign up
        setError(false);
        cognito.adminDeleteUser({
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: email,
        }).promise().then(() => {signUp()});
      }
      else {
        setError(true);
        setErrorText("Error: Something went wrong");
      }
    })
  };

  const signUp = (e) => {
    setCodeResent(false);
    setCodeError(false);
    setNpiError(false);
    setError(false);
    setPasswordError(false);
    setEmailError(false);
    setNameError(false);
    if(e) {
      e.preventDefault();
    }
   // console.log(NPI) 
    Auth.signUp({ username: email, password, attributes: { email } })
      .then((data) => {
        console.log(data);
        setWaitingForCode(true);
      })
      .catch((err) => {
        console.log(err);
        if(err.code === 'UsernameExistsException'){
          checkUser();
        }
        else if(err.code === 'InvalidParameterException'){
          setEmailErrorText("Invalid email address");
          setEmailError(true);
        }
        else{
          setError(true);
          setErrorText("Error: Please try again");
        }
      });
  };

  async function signIn() {
    await Auth.signIn(email, password).then(() => {
      dispatch(setRequireMFA(false));
    });
  }

  const showSignIn = (e) => {
    e.preventDefault();
    setWaitingForCode(false);
    setShowLogin(true);
  };

  const resendCode = () => {
    Auth.resendSignUp(email)
      .then(() => {
        console.log("code resent successfully");
        codeResent(true);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setError("Error resending code. Please try again.");
      });
  };

  const handleClickHelpOpen = () => {
    setOpen(true);
  };

  const handleClickHelpOK = () => {
    if(isDoctor) {
      if(helpEmail && helpNPI) {
        const sendHelpEmail = async () => {
          const response = await fetch(`${apiUrl}/doctors/${helpEmail}/sendhelpemail`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              doctorNPI: helpNPI,
            }),
          })
          console.log(response);
          console.log(response.json())
        }
    
        sendHelpEmail()
          .then(() => {
            setHelpSent(true);
            console.log(helpEmail, helpNPI)
            setHelpEmail(null);
            setHelpNPI(null);
            setOpen(false);
          })
      }
      else {
        setHelpError(true);
        setHelpErrorText("Error: Please fill out all fields");
      }
    }
    else {
      if(helpEmail && helpMessage) {
        const sendHelpEmail = async () => {
          const response = await fetch(`${apiUrl}/patients/${helpEmail}/sendhelpemail`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              message: helpMessage,
            }),
          })
          console.log(response);
          console.log(response.json())
        }
    
        sendHelpEmail()
          .then(() => {
            setHelpSent(true);
            console.log(helpEmail, helpMessage)
            setHelpEmail(null);
            setHelpMessage(null);
            setOpen(false);
          })
      }
      else {
        setHelpError(true);
        setHelpErrorText("Error: Please fill out all fields");
      }
    }
  };

  const handleClickHelpClose = () => {
    setHelp(false);
    setOpen(false);
    setHelpError(false);
  };

  return (
    <div>
      {!waitingForCode && !showLogin && (
        <ThemeProvider theme={theme}>
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
          <CssBaseline />
          <div>
            <form noValidate>
              <Grid item>
                {!isDoctor && (
                  <Typography component="h1" variant="h5" align="center">  
                    Patient Sign Up
                  </Typography>
                )}
                {isDoctor && (
                  <Typography component="h1" variant="h5" align="center">  
                    Doctor Sign Up
                  </Typography>
                )}
              </Grid>
              <Grid item align="center">
                <Avatar>
                  <LockOutlinedIcon />
                </Avatar>
              </Grid>
              <TextField
                margin="normal"
                variant="outlined"
                required
                fullWidth
                id="sign-up-email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                value={email}
                error={emailError}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && (
                <Grid item>
                  <Typography style={{ color: 'red', fontSize: '15px' }}>{emailErrorText}</Typography>
                </Grid>
              )}
              <TextField
                margin="normal"
                variant="outlined"
                required
                fullWidth
                id="sign-up-password"
                label="Password"
                name="password"
                autoComplete="password"
                type="password"
                value={password}
                error={passwordError}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && (
                <Grid item>
                  <Typography style={{ color: 'red', fontSize: '15px' }}>{passwordErrorText}</Typography>
                </Grid>
              )}
              {isDoctor && (
                <div>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    id="sign-up-npi"
                    label="NPI"
                    name="npi"
                    autoComplete="npi"
                    type="npi"
                    value={NPI}
                    error={npiError}
                    onChange={(e) => setNPI(e.target.value)}
                  />
                  {npiError && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{npiErrorText}</Typography>
                    </Grid>
                  )}
                  {error && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{errorText}</Typography>
                    </Grid>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={checkNPI}
                  >
                    Sign Up
                  </Button>
                {help && (
                  <Grid item style={{textAlign: 'center'}} xs={12}>
                    <Button 
                      type="button"
                      fullWidth
                      color="primary"
                      style={{marginTop: '16px'}} 
                      onClick={handleClickHelpOpen}
                    >
                      Need Help Signing Up? Contact Us
                    </Button>
                  </Grid>
                )}
                </div>
              )}
              {!isDoctor && (
                <>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    id="sign-up-name"
                    label="Name"
                    name="patientName"
                    autoComplete="name"
                    type="name"
                    value={patientName}
                    error={nameError}
                    onChange={(e) => setPatientName(e.target.value)}
                  />
                  {nameError && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{nameErrorText}</Typography>
                    </Grid>
                  )}
                  <TextField
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    id="sign-up-name"
                    label="Address"
                    name="patientAddress"
                    autoComplete="address"
                    type="address"
                    value={patientAddress}
                    onChange={(e) => setPatientAddress(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    id="sign-up-name"
                    label="Phone Number"
                    name="patientPhone"
                    autoComplete="phonenumber"
                    type="phone number"
                    value={patientPhone}
                    onChange={(e) => setPatientPhone(e.target.value)}
                  />
                  &nbsp;
                  <Grid item xs={12}>
                    <FormLabel component="legend">Preferred Method of Contact</FormLabel>
                    <RadioGroup aria-label="preferred contact" name="preferredmethod" value={patientPreferred} onChange={(e) => setPatientPreferred(e.target.value)}>
                      <FormControlLabel value="email" control={<Radio />} label="Email" />
                      <FormControlLabel value="phone" control={<Radio />} label="Phone" />
                    </RadioGroup>
                  </Grid>
                  &nbsp;
                  {error && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{errorText}</Typography>
                    </Grid>
                  )}
                  <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={checkPatientFields}
                  >
                  Sign Up
                  </Button>
                  <Grid item style={{textAlign: 'center'}} xs={12}>
                    <Button 
                      type="button"
                      fullWidth
                      color="primary"
                      style={{marginTop: '16px'}} 
                      onClick={handleClickHelpOpen}
                    >
                      Need Help Signing Up? Contact Us
                    </Button>
                  </Grid>
                </>
              )}
              <Button
                type="button"
                fullWidth
                color="primary"
                onClick={showSignIn}
                >
                Return to Sign In
              </Button>       
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
        </ThemeProvider>
      )}
      {waitingForCode && !showLogin && (
        <ThemeProvider theme={theme}>
        {codeResent && <Alert children={"Code Resent Successfully"}/>}
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
          <CssBaseline />
          <div>
            <Grid item>
              <Typography component="h1" variant="h5" align="center">  
                Sign Up
              </Typography>
            </Grid>
            <Grid item align="center">
              <Avatar>
                <LockOutlinedIcon />
              </Avatar>
            </Grid>
            <form noValidate>
              <TextField
                margin="normal"
                variant="outlined"
                required
                fullWidth
                id="sign-up-code"
                label="code"
                name="code"
                autoComplete="code"
                type="text"
                value={code}
                error={codeError}
                onChange={(e) => setCode(e.target.value)}
              />
              {codeError && (
                <Grid item>
                  <Typography style={{ color: 'red', fontSize: '15px' }}>{codeErrorText}</Typography>
                </Grid>
              )}
              <Box mb={1}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCompleteSignupRequest({
                      email: email,
                      code: code,
                      username: email
                    })
                  }}
                >
                Confirm Sign Up
                </Button>
              </Box>
              <Box>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={resendCode}
                >
                  Resend code
                </Button>
              </Box>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
        </ThemeProvider>
      )}
      {showLogin && (<SignIn />)}
      {helpSent && <Alert children={"Thank you, your help request has been submitted."}/>}
      <Dialog open={open} onClose={handleClickHelpClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Help</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill out the following information. We will contact you within 24 hours to help set up your account.
          </DialogContentText>
          <TextField
          autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            color="secondary"
            required
            onChange={(e) => setHelpEmail(e.target.value)}
          />
          {isDoctor ?
            <TextField
              margin="dense"
              id="npi"
              label="NPI"
              type="npi"
              fullWidth
              color="secondary"
              required
              onChange={(e) => setHelpNPI(e.target.value)}
            /> 
            :
            <TextField
              margin="dense"
              id="npi"
              label="Message"
              type="npi"
              fullWidth
              color="secondary"
              required
              onChange={(e) => setHelpMessage(e.target.value)}
            />
          }
          {helpError && (
            <Grid item>
              <Typography style={{ color: 'red', fontSize: '15px' }}>{helpErrorText}</Typography>
            </Grid>
          )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickHelpClose} color="textSecondary">
              Cancel
            </Button>
            <Button onClick={handleClickHelpOK} color="textSecondary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
};

export default CustomSignUp;