import React, { useState } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {Auth} from 'aws-amplify';
import { setRequireMFA } from './MFA-setup-slice';
import { useDispatch } from 'react-redux';
import SignIn from './SignIn';
import Copyright from "./copyright";

const is_prod = process.env.REACT_APP_IS_PROD === 'true';

const theme_blue = {
  light: "#ffffff",
  main: "#2c2c34", //changed
  dark: "#3c3c44",
  contrastText: "#44d3b4", //changed
};

const theme_black = {
  light: "#2c2c2c",
  main: "#000000",
  dark: "#000000",
  contrastText: "#ffffff",
};

const theme_orange = {
  light: "#ff935f",
  main: "#fc6132",
  dark: "#c22d01",
  contrastText: "#FFFFFF",
};

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: is_prod ? theme_blue : theme_orange,
    secondary: theme_black,
    orange: theme_orange,
  },
});

const TOTP = ({user}) => {
  const [TOTPCode, setTOTPCode] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const dispatch = useDispatch();

  async function signIn(e) {
    e.preventDefault();
    setError(false);
    await Auth.confirmSignIn(user, TOTPCode, 'SOFTWARE_TOKEN_MFA').then(() => {
      dispatch(setRequireMFA(false));
    }).catch( e => {
        console.log(e)
        setError(true);
        if(e.code === "CodeMismatchException") {
          setErrorText("Error: Incorrect code");
        }
        else {
          setErrorText("Error: Please enter a code");
        }        
    });
  }

  const showSignIn = (e) => {
    e.preventDefault();
    setShowLogin(true);
  };

  return (
    <div>
        {!showLogin &&
            <ThemeProvider theme={theme}>
            <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
                <CssBaseline />
                <div>
                <Grid item>
                    <Typography component="h1" variant="h5" align="center">  
                    Two-Factor Authentication
                    </Typography>
                </Grid>
                <Grid item align="center">
                    <Avatar>
                    <LockOutlinedIcon />
                    </Avatar>
                </Grid>
                <Grid item >
                    <Typography component="h1" variant="h6" >  
                    Provide the 6 digit code from the Authenticator App:
                    </Typography>
                </Grid>
                <Grid item>
                    <form noValidate>
                    <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="sign-up-code"
                        label="Code"
                        name="code"
                        autoComplete="code"
                        type="code"
                        value={TOTPCode}
                        error={error}
                        onChange={(e) => setTOTPCode(e.target.value)}
                    />
                    {error && (
                      <Grid item>
                        <Typography style={{ color: 'red', fontSize: '15px' }}>{errorText}</Typography>
                      </Grid>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={(e) => signIn(e)}
                    >
                        Sign In
                    </Button>
                    </form>
                    <Button
                    type="button"
                    fullWidth
                    color="primary"
                    onClick={showSignIn}
                    >
                    Return to Sign In
                    </Button> 
                </Grid>
                </div>
                <Box mt={8}>
                <Copyright />
                </Box>
            </Grid>
            </ThemeProvider>
        }
        {showLogin && <SignIn/>}
    </div>
    );    
};

export default TOTP;

