import React from 'react'
import App from './InternalApp'
import SignIn from './SignIn'

const AppWrapper = ({authState}) => {
  let mfaStatus = window.localStorage.getItem('state');
  let mfaResult = mfaStatus ? JSON.parse(mfaStatus) : undefined;
  console.log(mfaResult)
  let mfaRequired = true;
  if(mfaResult !== undefined) {
    mfaRequired = mfaResult.MFA.requireMFA;
  }

  if(authState === 'signedIn' && !mfaRequired) {
    return (
      <>
        <App/>
      </>
    )
  }
  else {
    return (
      <>
        <SignIn/>
      </>
    )
  }
}

export default AppWrapper;