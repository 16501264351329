import React, { useEffect, useState } from "react";
import { Loading } from ".";
import { useFetch } from "../utils";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const AWS = require('aws-sdk');
let nextToken = '';
const DISPLAY_PAGE = 10;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_REGION,
});
const cognito = new AWS.CognitoIdentityServiceProvider();

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const Doctors = withAuth0(() => {
  const classes = useStyles();
  const [ requestNumber, setRequestNumber ] = useState(0);
  const [userData, setUserData] = useState('');
  let { data, token, error } = useFetch(`/doctors`, requestNumber);
  const [ makeRoleRequest, setRoleRequest ] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  console.log(token)
  nextToken = token;
  const apiUrl = process.env.REACT_APP_API_URL;

  const modifyPageIndex = (isIncrement) => {
    console.log(nextToken);
    if (!isIncrement){
      setPageIndex(pageIndex - 1);
      return;
    } else {
      setPageIndex(pageIndex + 1);
    } 
    if ((pageIndex + 1) >= data.length / DISPLAY_PAGE && nextToken !== ''){
      fetchDoctorData()
      .then((result)=> { 
        const {userGroup, token} = result;
        console.log(result)
        nextToken = token;
        let currData = data;
        currData = currData.concat(userGroup)
        data = currData;

        setUserData(data);
    });
    setUserData(data);

    }
  }

  const fetchDoctorData = async () => {
    console.log(`${apiUrl}/doctors/${nextToken}`)
    const npiResponse = await fetch(`${apiUrl}/doctors/${nextToken}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    console.log(npiResponse)
    return npiResponse.json();
  }

  const updateRole = async (newRole) => {
    const response = await fetch(`${apiUrl}/users/${makeRoleRequest.username}/updaterole`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({userGroup: newRole}),
    });

    return response.json();
  }

  const updatePrescriberPrivileges = async (prescriberStatus) => {
    const npiResponse = await fetch(`${apiUrl}/doctors/${makeRoleRequest.username}/prescriber`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({prescriber: prescriberStatus}),
    })
    console.log(npiResponse)
    return npiResponse.json();
  }

  useEffect(() => {
    if(!makeRoleRequest){
      return;
    }

    const fetchData = async () => {
      if (makeRoleRequest.role === "Admin"){
        console.log("set role request: " + makeRoleRequest.role)
        updateRole("Admin").then((response)=> {
          console.log(response);
          setRoleRequest(null);
          setRequestNumber(r => r + 1);
        });
      }
      else if (makeRoleRequest.role === "Patient"){
        console.log("set role request: " + makeRoleRequest.role)
        updateRole("Patient").then((response)=> {
          console.log(response);
          setRoleRequest(null);
          setRequestNumber(r => r + 1);
        });
      }
      else if (makeRoleRequest.role === "PrescribingDoctor"){
        console.log("set role request: " + makeRoleRequest.role)
        updatePrescriberPrivileges(true).then((response)=> {
          console.log(response);
          setRoleRequest(null);
          setRequestNumber(r => r + 1);
        });
      }
      else if (makeRoleRequest.role === "PrescribingDoctorRemove"){
        console.log("set role request: " + makeRoleRequest.role)
        updatePrescriberPrivileges(false).then((response)=> {
          console.log(response);
          setRoleRequest(null);
          setRequestNumber(r => r + 1);
        });
      }
      else {
        console.log("set role request: " + makeRoleRequest.role)
        updateRole("Manufacturer").then((response)=> {
          console.log(response);
          setRoleRequest(null);
          setRequestNumber(r => r + 1);
        });
      }
    }

    fetchData();
  }, [makeRoleRequest]);

  async function deleteAccount(username) {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const npiResponse = await fetch(`${apiUrl}/users/${username}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
      console.log(npiResponse)
      return npiResponse.json();
    }

    fetchData()
      .then(()=> {
        cognito.adminDeleteUser({
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: username,
        }).promise()
          .then(() => {
            setRequestNumber(r => r + 1);
          })
      });
  };

  const IsolatedMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
   
    return(
    <React.Fragment>
      <Button
      variant ="contained"
      aria-label="outlined primary button group"
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={event => setAnchorEl(event.currentTarget)}
      >
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.doctor,
              role: "PrescribingDoctor"
            })
        }}>Grant Prescribing Privileges</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.doctor,
              role: "PrescribingDoctorRemove"
            })
        }}>Remove Prescribing Privileges</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.doctor,
              role: "Admin"
            })
        }}>Make Admin</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.doctor,
              role: "Patient"
            })
        }}>Make Patient</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.doctor,
              role: "Manufacturer"
            })
        }}>Make Manufacturer</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            deleteAccount(props.doctor);
        }}>Delete Account</MenuItem>
      </Menu>
  
    </React.Fragment>
    )
  }

  if ((!data && !error) || makeRoleRequest) {
    return <Loading />;
  }

  let dataCount = 1;
  let dataSubset = [];
  if (userData){
    console.log(userData);
    if ((userData.length / DISPLAY_PAGE) != 0){
      dataCount = Math.floor((userData.length / DISPLAY_PAGE)) + 1;
    } else {
      dataCount = Math.floor((userData.length / DISPLAY_PAGE)) + 1;
    }
    dataSubset = userData.slice(pageIndex* DISPLAY_PAGE, (pageIndex*DISPLAY_PAGE) + DISPLAY_PAGE)
  }

  if (userData == '' && data){
    console.log(data);
    if ((userData.length / DISPLAY_PAGE) != 0){
      dataCount = Math.floor((data.length / DISPLAY_PAGE)) + 1;
    } else {
      dataCount = Math.floor((data.length / DISPLAY_PAGE)) + 1;
    }
    dataSubset = data.slice(pageIndex* DISPLAY_PAGE, (pageIndex*DISPLAY_PAGE) + DISPLAY_PAGE)
  }



  return (
      <>
        <Container className={classes.container}>
          <Typography variant="h4">Doctors</Typography>
          &nbsp;
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <Typography variant="h5">
                Doctor E-mail
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5">
                Name
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5">
                Location
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h5">
                NPI
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h5">
                Prescribing Doctor
              </Typography>
            </Grid>

            {(userData || data) && dataSubset.sort((a, b) => a.username - b.username).map(doctor => {
              return <React.Fragment key={doctor.username}>
                <Grid item xs={5}>
                  <Typography variant="body1">
                    {doctor.username}
                  </Typography>
                </Grid>
                <Grid item xs={2}>{doctor.name}</Grid>
                <Grid item xs={2}>{doctor.location}</Grid>
                <Grid item xs={1}>{doctor.npi}</Grid>
                <Grid item xs={1}>{doctor.prescriber ? "Yes" : "No"}</Grid>
                <Grid item xs={1} style={{textAlign: 'left'}}>
                  <IsolatedMenu doctor={doctor.username}/>
                </Grid>
              </React.Fragment>;
            })}
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12}>
              <Button onClick={() => modifyPageIndex(false)} disabled={pageIndex === 0}><ArrowBackIosIcon/></Button>
              <Button onClick={() => modifyPageIndex(true)} disabled={nextToken == '' && ((pageIndex+1)*DISPLAY_PAGE >= data.length)}><ArrowForwardIosIcon/></Button>
              </Grid>
            </Grid>

          </Grid>
        </Container>
      </>
    );
  }
);

export default Doctors;
