import { createSlice } from '@reduxjs/toolkit';

export const mfaSetupSlice = createSlice({
    name: "mfaSlice",
    initialState: {
        requireMFA: true,
    },
    reducers: {
        setRequireMFA: (state, action) => {
            state.requireMFA = action.payload;
        },
    }
});

export const getRequireMFA = state => state.MFA.requireMFA;

export const { setRequireMFA } = mfaSetupSlice.actions;
export default mfaSetupSlice.reducer;