import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import SignIn from './SignIn'
import Alert from './components/snackbar-message';
import { setRequireMFA } from './MFA-setup-slice';
import { useDispatch } from 'react-redux';
import Copyright from "./copyright";

const is_prod = process.env.REACT_APP_IS_PROD === 'true';

const theme_blue = {
  light: "#ffffff",
  main: "#2c2c34", //changed
  dark: "#3c3c44",
  contrastText: "#44d3b4", //changed
};

const theme_black = {
  light: "#2c2c2c",
  main: "#000000",
  dark: "#000000",
  contrastText: "#ffffff",
};

const theme_orange = {
  light: "#ff935f",
  main: "#fc6132",
  dark: "#c22d01",
  contrastText: "#FFFFFF",
};

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: is_prod ? theme_blue : theme_orange,
    secondary: theme_black,
    orange: theme_orange,
  },
});
  
const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [passwordErrorText, setPasswordErrorText] = useState('');
  const [codeErrorText, setCodeErrorText] = useState('');
  const [codeResent, setCodeResent] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const dispatch = useDispatch();

  const forgotPassword = (e) => {
    e.preventDefault();
    setError(false);
    setPasswordError(false);
    setCodeError(false);
    setCodeResent(false);

    Auth.forgotPassword(email)
      .then((data) => {
        console.log(data);
        setWaitingForCode(true);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        if(err.code === 'UserNotFoundException'){
          setErrorText("Error: User does not exist");
        }
        if(err.code === 'InvalidParameterException'){
          setErrorText("Error: Cannot reset password for the user as there is no registered/verified email or phone number");
        }
        else{
          setErrorText("Error: Please enter valid email address");
        }

      });
  };

  const forgotPasswordSubmit = (e) => {
    e.preventDefault();
    setError(false);
    setPasswordError(false);
    setCodeError(false);
    setCodeResent(false);

    Auth.forgotPasswordSubmit(email, code, password)
      .then((data) => {
        console.log(data)
        signIn();
      })
      .catch((err) => {
        console.log(err);
        if(err.code === 'CodeMismatchException'){
          setCodeError(true);
          setCodeErrorText("Error: Incorrect code");
        }
        else if(err.code === 'InvalidPasswordException'){
          setPasswordError(true);
          setPasswordErrorText("Error: Your password must be at least 8 characters long");
        }
        else{
          setError(true);
          setErrorText("Error: Please try again");
        }

      });
  };

  const resendCode = () => {
    setError(false);
    setPasswordError(false);
    setCodeError(false);
    setCodeResent(false);

    Auth.forgotPassword(email)
      .then((data) => {
        console.log(data)
        setCodeResent(true);
        setWaitingForCode(true);
      })
      .catch((err) => {
          console.log(err)
          setCodeError(true);
          setCodeErrorText("Error: Please try again");
      }); 
  };

  async function signIn() {
    console.log("signing in")
    try {
      //TODO: Redirect reset password to TOTP pages for admins upon successful password reset
      await Auth.signIn(email, password).then((result) => {
        if (result.challengeName === 'SOFTWARE_TOKEN_MFA') {
          console.log("TOTP MFA challenge")
          setResetSuccess(true);
          showSignIn();
        } 
        else if (result.challengeName === 'MFA_SETUP') {
          console.log("need to set up TOTP")
          setResetSuccess(true);
          showSignIn();
        }
        else { 
          let role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString();
          let findAdmin = role.indexOf("Admin");
          //If they haven't logged in since 2FA was required and they're an admin, set them up with TOTP
          if(findAdmin !== -1) {
            console.log("haven't logged in since 2FA was required, need to set up TOTP")
            setResetSuccess(true);
            showSignIn();
          }
          else {
            //If they're not an admin, don't require 2FA
            dispatch(setRequireMFA(false));
          }
        }
      });
    } catch (err) {
        if (err.code === 'UserNotConfirmedException') {
          await Auth.resendSignUp(email);
          setError(true);
          setErrorText("Error: Your account must be approved before you can log in");
        } else {
          console.log(err)
          setError(true);
          setErrorText("Error. Please try again");
        }
    }
  }

  const showSignIn = () => {
    setWaitingForCode(false);
    setShowLogin(true);
  };

  return (
    <div>
      {!waitingForCode && !showLogin && (
        <ThemeProvider theme={theme}>
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
          <CssBaseline />
          <div>
            <Grid item>
              <Typography component="h1" variant="h5" align="center">  
                Reset Password
              </Typography>
            </Grid>
            <Grid item align="center">
              <Avatar>
                <LockOutlinedIcon />
              </Avatar>
            </Grid>
            <Grid item>
              <form noValidate>
                <TextField
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  id="sign-up-email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  type="email"
                  value={email}
                  error={error}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error && (
                  <Grid item>
                    <Typography style={{ color: 'red', fontSize: '15px' }}>{errorText}</Typography>
                  </Grid>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={forgotPassword}
                >
                  Reset Password
                </Button>
                <Button
                  type="button"
                  fullWidth
                  color="primary"
                  onClick={showSignIn}
                  >
                  Return to Sign In
                </Button>
              </form>
            </Grid>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Grid>
        </ThemeProvider>
      )}
      {waitingForCode && !showLogin && (
        <>
          {codeResent && <Alert children={"Code Resent Successfully"}/>}
          <ThemeProvider theme={theme}>
          <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
            <CssBaseline />
            <div>
              <Grid item>
                <Typography component="h1" variant="h5" align="center">  
                  Reset Password
                </Typography>
              </Grid>
              <Grid item align="center">
                <Avatar>
                  <LockOutlinedIcon />
                </Avatar>
              </Grid>
              <Grid item> 
                <form noValidate>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    id="sign-up-code"
                    label="code"
                    name="code"
                    autoComplete="code"
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    id="new-password"
                    label="password"
                    name="password"
                    autoComplete="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {codeError && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{codeErrorText}</Typography>
                    </Grid>
                  )}
                  {passwordError && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{passwordErrorText}</Typography>
                    </Grid>
                  )}
                  {error && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{errorText}</Typography>
                    </Grid>
                  )}
                  <Box mb={1}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={forgotPasswordSubmit}
                    >
                      Confirm Reset Password
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={resendCode}
                    >
                      Resend code
                    </Button>
                  </Box>
                  <Box mt={8}>
                    <Copyright />
                  </Box>
                </form>
              </Grid>
            </div>
          </Grid>
          </ThemeProvider>
        </>
      )}
      {(showLogin && resetSuccess) && (<><Alert children={"Password Reset Successfully"}/><SignIn /></>)}      
      {(showLogin && !resetSuccess) && <SignIn />}
    </div>
  );
};

export default ResetPassword;