import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import { Loading } from ".";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const Devices = withAuth0(() => {
  const classes = useStyles();
  const [data, setData] = useState(""); 

  const fetchDevices = async() =>
  {
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log(`${apiUrl}/devices`)
    const prescriptionResponse = await fetch(`${apiUrl}/devices`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    return prescriptionResponse.json();
  }

  async function processPrescriptionData() {
    fetchDevices()
      .then((devicesResult) => {
        let devices = [];
        let decSernum, hexSernum, formatSernum;
        for(let i=0; i < devicesResult.length; i++) {
          decSernum = parseInt(devicesResult[i].deviceId);
          hexSernum = ('000000000000' + decSernum.toString(16).toUpperCase()).slice(-12); //convert to hex and pad the sernum
          formatSernum = hexSernum.slice(0,2) + ":" + hexSernum.slice(2,4) + ":" + hexSernum.slice(4,6) + ":" + hexSernum.slice(6,8) + ":" + hexSernum.slice(8,10) + ":" + hexSernum.slice(10);
          devices.push({sernum: formatSernum, email: devicesResult[i].userId})
        }

        function compare( a, b ) {
          if ( a.sernum < b.sernum ){
            return -1;
          }
          if ( a.sernum > b.sernum ){
            return 1;
          }
          return 0;
        }
          
        devices.sort( compare );
        setData(devices);
      });
  };

  useEffect(() => {
    processPrescriptionData();
  }, []);

  async function deleteDevice(deviceId) { 
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const npiResponse = await fetch(`${apiUrl}/devices/${deviceId}`, {
        method: "DELETE"
      })
      return npiResponse;
    }

    if (window.confirm("Are you sure you'd like to delete device " + deviceId + "?")) {
      deviceId = deviceId.replace(/:/g, '');
      deviceId = parseInt(deviceId, 16);
      fetchData()
      .then(()=> {
        //refresh the page
        console.log(deviceId + " deleted.")
        processPrescriptionData()
          .then((result ) => {
            setData(result)
          })
      });
    };
  };

  if (!data) {
    return <Loading />;
 }

  console.log(data)
  return (
        <>
          <Container className={classes.container}>
            <Typography variant="h4">Devices</Typography>
              &nbsp;
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Typography variant="h5">
                  Serial Number
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography variant="h5">
                  Patient E-mail
                </Typography>
              </Grid>  
              {data && data.map(device => {
                return <React.Fragment key={device.sernum}>
                  <Grid item xs={3}>
                    <Typography variant="body1">
                      {device.sernum}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>{device.email}</Grid> 
                  <Grid item xs={5}>
                    <Button 
                     onClick={() => deleteDevice(device.sernum)}
                     alignItems="left"
                    >
                      <DeleteIcon />  
                    </Button>       
                  </Grid>
                </React.Fragment>;
                })}
            </Grid>
          </Container>
          </>
        );

});

export default Devices;
