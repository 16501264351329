import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import awsconfig from "../aws-exports";
import Amplify from "aws-amplify";
import { Auth } from 'aws-amplify';
import { Loading } from ".";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

Amplify.configure(awsconfig);

const Profile = withAuth0(() => {
  const classes = useStyles();
  const [NPI, setNPI] = useState("");
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [location, setLocation] = useState(null);
  const [validNPI, setValidNPI] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showData, setShowData] = useState(false); 
  const [error, setError] = useState(false);
  const errorText = "Invalid NPI";
  const apiUrl = process.env.REACT_APP_API_URL;

  async function updateNPI(new_npi, doctor_email, name, location) {
    const updateResponse = await fetch(`${apiUrl}/doctors/${doctor_email}/npi`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        npi: new_npi,
        doctor_name: name,
        doctor_location: location,
      }),
    });
    console.log(updateResponse)
  }

  useEffect(() => {
    let email = Auth.user.username;
    const fetchData = async () => {
      setEmail(email);
      const npiResponse = await fetch(`${apiUrl}/doctors/${email}/npi`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      return npiResponse.json();
    }
    fetchData()
      .then((data)=> {   
        console.log(data)
        let valid_npi = data[0]["validNPI"];
        let npi = data[0]["NPI"];
        let name = data[0]["name"];
        let location = data[0]["location"];
        if(npi && valid_npi){
          setValidNPI(valid_npi);
          setNPI(npi);
          setName(name);
          setLocation(location);
          setShowSearch(false);
          setShowData(true); 
        }
        else{
          setShowSearch(true);
          setShowData(false);
        }
      });

  }, []);

  const lookupNPI = async () => {
    const npiResponse = await fetch(`${apiUrl}/npi/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({npi: NPI}),
    })
    return npiResponse.json();
  }

  const checkNPI = async (NPI) => {
    console.log(NPI)
    const len = Math.ceil(Math.log10(NPI));
    if (len === 10){
      lookupNPI()
        .then((result) => {
          console.log(result)
          if(result.length === 1) {
            setValidNPI(true);
            setNPI(NPI);
            setName(result[0].name);
            setLocation(result[0].location);
            updateNPI(NPI, true, email, result[0].name, result[0].location);
            setShowSearch(false);
            setShowData(true); 
          }
          else {
            setValidNPI(false);
            setShowSearch(true);
            setError(true);
          }
        });
    }
    else {
      setValidNPI(false);
      setShowSearch(true);
      setError(true);
    }
  }
  
  if (!showData && !showSearch){
      return (
        <Loading />
      )
  } 

  else{
      return (
        <>
          <Container className={classes.container}>
            <Typography variant="h4">Your Profile</Typography>
            &nbsp;
            {showSearch && (
                <Grid container spacing={1}>
                <Grid item xs={2} id="NPI">
                    <TextField 
                    id="standard-basic"
                    label="Enter Your NPI"
                    error={error}
                    onChange={(e) => setNPI(e.target.value)} />    
                </Grid>
                <Grid item style={{textAlign: 'left'}} xs={10}>
                    <Button 
                    variant='contained' 
                    style={{marginTop: '16px'}} 
                    onClick={function () { checkNPI(NPI); }}>
                    Verify
                    </Button>
                </Grid>
                {error && (
                    <Grid item>
                      <Typography style={{ color: 'red', fontSize: '15px' }}>{errorText}</Typography>
                    </Grid>
                )}
                </Grid>
            )}
          </Container>
          {showData && (
            <Container>
                <Typography variant="h6"><b>NPI: </b>{NPI}</Typography>
                <br/>
                <Typography variant="h6"><b>Valid NPI: </b>{validNPI.toString().toUpperCase()}</Typography>
                <br/>
                <Typography variant="h6"><b>Name: </b>{name}</Typography>
                <br/>
                <Typography variant="h6"><b>Location: </b>{location}</Typography>
            </Container>     
          )}
        </>
    );
  }

});

export default Profile;
