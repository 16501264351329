import React, {useEffect, useState } from 'react';
import { Switch, Route} from "react-router-dom";
import Container from "@material-ui/core/Container";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Doctors, Footer, Loading} from "./components";
import Admins from "./components/admins";
import Patients from "./components/patients";
import Home from "./views/home";
import Profile from "./components/profile";
import Unconfirmed from "./components/unconfirmed";
import UnconfirmedPatients from './components/unconfirmed-patients';
import Manufacturers from "./components/manufacturers"
import AddPatient from './components/addpatient';
import { Auth } from 'aws-amplify';
import awsconfig from "./aws-exports";
import Amplify from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  Drawer, 
  IconButton,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Sessions from './components/sessions';
import Prescriptions from './components/prescriptions';
import Devices from './components/devices';
import PatientProfile from './components/patient-profile';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from 'react-redux';
import { setRequireMFA } from './MFA-setup-slice';
import Alert from './components/snackbar-message';
import {version as app_version} from './version';

Amplify.configure(awsconfig);

const is_prod = process.env.REACT_APP_IS_PROD === 'true';

const theme_blue = {
  light: "#ffffff",
  main: "#2c2c34", //changed
  dark: "#3c3c44",
  contrastText: "#44d3b4", //changed
};

const theme_black = {
  light: "#2c2c2c",
  main: "#000000",
  dark: "#000000",
  contrastText: "#ffffff",
};

const theme_orange = {
  light: "#ff935f",
  main: "#fc6132",
  dark: "#c22d01",
  contrastText: "#FFFFFF",
};

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: is_prod ? theme_blue : theme_orange,
    secondary: theme_black,
    orange: theme_orange,
  },
});

const useStyles = makeStyles(() => ({
  appBar: {
    top: "auto",
    bottom: 0
  },
}));

export default function App() {
    const [userRole, setUserRole] = useState(null);  
    const [navOpen, setNavOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [helpMessage, setHelpMessage] = useState("");
    const [helpSent, setHelpSent] = useState(false);
    const [helpError, setHelpError] = useState(false);
    const [openResetPassword, setOpenResetPassword] = useState(false);
    const [code, setCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [error, setError] = useState("");
    const [errorText, setErrorText] = useState("");
    const [resetSuccess, setResetSuccess] = useState(false);
    const helpErrorText = "Error: Message required";
    let username = (Auth.user.username).toString();
    const classes = useStyles();
    const dispatch = useDispatch();

    async function signOut() {
      dispatch(setRequireMFA(true));
      Auth.signOut({ global: true }); 
    };
    
    useEffect(() => {
      let role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString();
      setUserRole(role);
    }, []);

    if(!userRole){
      // Loading
  
      return (
        <ThemeProvider theme={theme}>
          <Loading />
        </ThemeProvider>
      );
    }

    const handleClickHelpOpen = () => {
      setOpen(true);
    };
  
    const handleClickHelpOK = () => {
      if(helpMessage) {
        const sendHelpEmail = async () => {
          const apiUrl = process.env.REACT_APP_API_URL;
          let helpEmail = Auth.user.signInUserSession.idToken.payload["email"];
          const response = await fetch(`${apiUrl}/patients/${helpEmail}/sendhelpemail`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              message: helpMessage,
            }),
          })
          console.log(response.json())
        }
      
        sendHelpEmail()
          .then(() => {
            setHelpSent(true);
            setHelpMessage(null);
            setOpen(false);
          })
      }
      else {
        setHelpError(true);
      }
    };
  
    const handleClickHelpClose = () => {
      setOpen(false);
      setHelpError(false);
    };

    const handleClickResetOpen = () => {
      setOpenResetPassword(true);
      setResetSuccess(false);
      let email = Auth.user.signInUserSession.idToken.payload["email"];

      Auth.forgotPassword(email)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.code)
        if(err.code === 'LimitExceededException'){
          setErrorText("Error: Attempt limit exceeded, please try again after some time");
        }
        else{
          setErrorText("Error: Please try again");
        }
        setError(true);
      });
    };

    const handleClickResetOK = () => {
      if(code && newPassword) {
        let email = Auth.user.signInUserSession.idToken.payload["email"];
        setError(false);
            
        Auth.forgotPasswordSubmit(email, code, newPassword)
          .then((data) => {
            console.log(data)
            setResetSuccess(true);
            setOpenResetPassword(false);
            setError(false);
          })
          .catch((err) => {
            console.log(err);
            if(err.code === 'CodeMismatchException'){
              setError(true);
              setErrorText("Error: Incorrect code");
            }
            else if(err.code === 'InvalidPasswordException'){
              setError(true);
              setErrorText("Error: Your password must be at least 8 characters long");
            }
            else{
              setError(true);
              setErrorText("Error: Please try again");
            }
          });
      }
      else {
        setError(true);
        setErrorText("Error: All fields must be filled out");
      }
    };

    const handleResetPasswordClose = () => {
      setOpenResetPassword(false);
      setError(false);
    };
  
    if (userRole == "Patient"){
      return(
        <ThemeProvider theme={theme}>
          <Dialog
            open={true}
            disableBackdropClick={true}
            BackdropProps={{ invisible: true }}
          >
            <DialogTitle style={{ textAlign: "center" }}>
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                style={{ width: "10vw" }}
                alt=""
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center", overflow: "hidden" }}>
              <Typography variant="h6">
               Welcome!
              </Typography>
              <br/>
              <br/>
              <Button variant="contained" color="primary" href="https://hipaa.jotform.com/211946045964058">
                Have a written prescription?<br/>Upload it and purchase a device
              </Button>
              <br/>
              <br/>
              <Button variant="contained" color="primary" href="https://hipaa.jotform.com/211938770573060">
                Need a prescription?<br/>Request a Telehealth appointment
              </Button>
              <br/>
              <br/>
              <Button variant="contained" color="primary" href="https://hipaa.jotform.com/211945165611049">
                Pay for your iTEAR Device
              </Button>
              <br/>
              <br/>
              <Button variant="contained" color="primary" onClick={handleClickHelpOpen}>
                Need Help? Contact us
              </Button>
              <br/>
              <br/>
              <Button variant="contained" color="primary" onClick={handleClickResetOpen}>
                Reset Password
              </Button>
              <br/>
              <br/>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={signOut}
              >
                Sign Out
              </Button>
            </DialogContent>
          </Dialog>
          <Dialog open={open} onClose={handleClickHelpClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Help</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please fill out the following information. We will contact you within 24 hours to respond to your inquiry.
            </DialogContentText>
            <TextField
              margin="dense"
              id="npi"
              label="Message"
              type="npi"
              fullWidth
              color="secondary"
              required
              error={helpError}
              onChange={(e) => setHelpMessage(e.target.value)}
            />
            {helpError && (
              <Grid item>
                <Typography style={{ color: 'red', fontSize: '15px' }}>{helpErrorText}</Typography>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickHelpClose} color="textSecondary">
              Cancel
            </Button>
            <Button onClick={handleClickHelpOK} color="textSecondary">
              OK
            </Button>
          </DialogActions>
          </Dialog>
          <Dialog open={openResetPassword} onClose={handleResetPasswordClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              A code was sent to your email. Please enter the code and your new password below:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="code"
              label="Code"
              type="code"
              fullWidth
              color="secondary"
              required
              onChange={(e) => setCode(e.target.value)}
            />
            <TextField
              margin="dense"
              id="password"
              label="New Password"
              type="password"
              fullWidth
              color="secondary"
              required
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {error && (
              <Grid item>
                <Typography style={{ color: 'red', fontSize: '15px' }}>{errorText}</Typography>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleResetPasswordClose} color="textSecondary">
              Cancel
            </Button>
            <Button onClick={handleClickResetOK} color="textSecondary">
              OK
            </Button>
          </DialogActions>
          </Dialog>
          {helpSent && <Alert children={"Thank you, your help request has been submitted."}/>}
          {resetSuccess && <Alert children={"Your password has been successfully reset."}/>}
        </ThemeProvider>
      );   
    }
    else if (userRole == "Unconfirmed"){
      return(
        <ThemeProvider theme={theme}>
          <Dialog
            open={true}
            disableBackdropClick={true}
            BackdropProps={{ invisible: true }}
          >
            <DialogTitle style={{ textAlign: "center" }}>
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                style={{ width: "10vw" }}
                alt=""
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center", overflow: "hidden" }}>
              Your account is still pending approval
              <br/>
              <br/>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={signOut}
              >
                Sign Out
              </Button>
            </DialogContent>
          </Dialog>
        </ThemeProvider>
      );  
    }
    else if (userRole == "Manufacturer"){
      return(
        <ThemeProvider theme={theme}>
          <Dialog
            open={true}
            disableBackdropClick={true}
            BackdropProps={{ invisible: true }}
          >
            <DialogTitle style={{ textAlign: "center" }}>
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                style={{ width: "10vw" }}
                alt=""
              />
            </DialogTitle>
            <DialogContent style={{ textAlign: "center", overflow: "hidden" }}>
              Manufacturers must use Manufacturing App
              <br/>
              <br/>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={signOut}
              >
                Sign Out
              </Button>
            </DialogContent>
          </Dialog>
        </ThemeProvider>
      );  
    }

    var findAdmin = userRole.indexOf("Admin");
    var routes;
    
    if(findAdmin !== -1){
      routes = {
        "/": { title: "Patients", component: Patients, exact: true },
        "/doctors": { title: "Doctors", component: Doctors, exact: true },
        "/admins": { title: "Admins", component: Admins, exact: true },
        //"/manufacturing": { title: "Manufacturing", component: Manufacturing, exact: true },
        "/unconfirmed": { title: "Unconfirmed Doctors", component: Unconfirmed, exact: true },
        "/manufacturers": { title: "Manufacturers", component: Manufacturers, exact: true },
        "/devices": { title: "Devices", component: Devices, exact: true },
        "/addpatient": { title: "Add Patient", component: AddPatient, exact: true },
        "/unconfirmedpatients": { title: "Unconfirmed Patients", component: UnconfirmedPatients, exact: true },
      };
    }
    else {
      routes = {
        "/": { title: "Home", component: Home, exact: true },
        "/patients": { title: "Patients", component: Patients, exact: true },
        "/profile": { title: "Profile", component: Profile, exact: true},
      };
    }

  
    return (
      <ThemeProvider theme={theme}>
        <AppBar position="static">
          <Toolbar>
            {/* on smaller screens show page title and a menu for nav */}
            <Box
              display={{ xs: "inherit", md: "none" }}
              style={{ alignItems: "center" }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  setNavOpen(true);
                }}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={
                  process.env.PUBLIC_URL + "/logo.png"
                }
                style={{ height: "36px" }}
                alt=""
              />
              &nbsp; &nbsp;
              <Typography variant="h6">
                {/* { routes[this.props.location.pathname].title } */}
              </Typography>
              <Drawer
                anchor="left"
                open={navOpen}
                onClose={() => {
                  setNavOpen(false);
                }}
              >
                <List
                  onClick={() => {
                    setNavOpen(false);
                  }}
                  onKeyDown={() => {
                    setNavOpen(false);
                  }}
                >
                  {Object.entries(routes).map(([path, val]) => {
                    return (
                      <ListItem
                        button
                        key={path}
                        component={RouterLink}
                        to={path}
                      >
                        <ListItemText primary={val.title} />
                      </ListItem>
                    );
                  })}
                </List>
              </Drawer>
            </Box>
  
            {/* show links in header on larger screens */}
            <Box
              display={{ xs: "none", md: "inherit" }}
              style={{ alignItems: "center" }}
            >
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                style={{ height: "30px" }}
                alt=""
              />
              &nbsp;
              <img
                src={
                  process.env.PUBLIC_URL + "/Olympic.Wordmark.png"
                }
                style={{ height: "30px" }}
                alt=""
              />
              &nbsp; &nbsp;
              {Object.entries(routes).map(([path, val]) => {
                return (
                  <Button
                    color="inherit"
                    component={RouterLink}
                    to={path}
                    key={path}
                  >
                    {val.title}
                  </Button>
                );
              })}
            </Box>
            <div style={{ flexGrow: 1 }} />
            <div style={{ flexGrow: 1 }} />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={signOut}
            >
              Sign Out
            </Button>
          </Toolbar>
        </AppBar>
        <Container className="flex-grow-1 mt-5" style={{marginTop: '10px'}}>
          <Switch>
            {Object.entries(routes).map(([path, val]) => {
              var Component = val.component;
              return (
                <Route
                  path={path}
                  render={() => <Component />}
                  exact={val.exact}
                  key={path}
                />
              );
            })}
            <Route path="/patientprofile/:userId" component={PatientProfile} />
            <Route path="/sessions/:userId" component={Sessions} />
            <Route path="/prescriptions/:userId" component={Prescriptions} />
          </Switch>
          <Toolbar>    
            <div style={{ flexGrow: 1 }} />
          </Toolbar>
        </Container>
        <Footer />
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar>    
            <Typography>Welcome, {username}!</Typography>
            <div style={{ flexGrow: 1 }} />
            <Typography component="h7" variant="h7">{app_version}</Typography>
          </Toolbar>
        </AppBar>

      </ThemeProvider> 
    );
  }