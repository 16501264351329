import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import 'date-fns';
import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';

const is_prod = process.env.REACT_APP_IS_PROD === 'true';

const theme_blue = {
  light: "#ffffff",
  main: "#2c2c34", //changed
  dark: "#3c3c44",
  contrastText: "#44d3b4", //changed
};

const theme_black = {
  light: "#2c2c2c",
  main: "#000000",
  dark: "#000000",
  contrastText: "#ffffff",
};

const theme_orange = {
  light: "#ff935f",
  main: "#fc6132",
  dark: "#c22d01",
  contrastText: "#FFFFFF",
};

const theme = createMuiTheme({
  props: {
    // Name of the component
    MuiButton: {
      // The properties to apply
      //variant: 'contained'
    },
  },
  palette: {
    primary: is_prod ? theme_blue : theme_orange,
    secondary: theme_black,
    orange: theme_orange,
  },
});

const DoctorOptions = withAuth0(() => { 
  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }}>
          <CssBaseline />
          <div>
            <Grid item>
              <Typography component="h4" variant="h4" align="center">
                Welcome!         
              </Typography>
            </Grid>
            <br/>
            <br/>
            <Grid item align="center">
              <Button variant="contained" color="primary" href="https://hipaa.jotform.com/211874329611052">
                Write a Prescripton for a Patient
              </Button>
            </Grid>
            <br/>
            <br/>
            <Grid item align="center">
              <Button variant="contained" color="primary" href="https://hipaa.jotform.com/211945960311049">
                Order an iTEAR Supply
              </Button>
            </Grid>
          </div>
        </Grid>
      </ThemeProvider>
      </>
    );
});

export default DoctorOptions;
