import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio, 
  Box
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import awsconfig from "../aws-exports";
import Amplify from "aws-amplify";
import { useParams } from "react-router-dom";
import Alert from './snackbar-message';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

Amplify.configure(awsconfig);

const Profile = withAuth0(() => {
  const classes = useStyles();
  const [patientName, setPatientName] = useState(null);
  const [address, setAddress] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [preferredMethod, setPreferredMethod] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [saved, setSaved] = useState(false);
  const nameErrorText = "Name is required";
  let { userId } = useParams();

  async function saveChanges() {
    setSaved(false);
    if(patientName) {
      console.log(patientName)
      console.log(address)
      console.log(phoneNumber)
      console.log(preferredMethod)
      const apiUrl = process.env.REACT_APP_API_URL;
      const updateResponse = await fetch(`${apiUrl}/patients/${userId}/updateprofile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: patientName,
          address: address,
          phone_number: phoneNumber,
          preferred_contact_method: preferredMethod,
        }),
      });
      console.log(updateResponse);
      if(updateResponse.status === 200) {
        setSaved(true);
      }
    }
    else {
      setNameError(true);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const npiResponse = await fetch(`${apiUrl}/patients/${userId}/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      return npiResponse.json();
    }
    fetchData()
      .then((data)=> { 
        if(data.length > 0) {
          setPatientName(data[0]["name"]);
          setAddress(data[0]["address"]);
          setPhoneNumber(data[0]["phone_number"]);
          setPreferredMethod(data[0]["preferred_contact_method"]);
          console.log(data)
        }
      });
  }, []);

  return(
    <>
      <Container className={classes.container}>
        <Typography variant="h4">Profile: {userId}</Typography>
        &nbsp;
        <TextField
          margin="normal"
          variant="outlined"
          required
          fullWidth
          id="sign-up-name"
          label="Patient Name"
          name="patientName"
          autoComplete="name"
          type="name"
          value={patientName || ""}
          error={nameError}
          onChange={(e) => setPatientName(e.target.value)}
        />
        {nameError && (
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '15px' }}>{nameErrorText}</Typography>
          </Grid>
        )}
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="sign-up-number"
          label="Phone Number"
          name="patientName"
          autoComplete="phone"
          type="phone"
          value={phoneNumber || ""}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="sign-up-address"
          label="Address"
          name="patientAddress"
          autoComplete="address"
          type="address"
          value={address || ""}
          onChange={(e) => setAddress(e.target.value)}
        />
        <br/>
        <br/>
        <Grid item xs={12}>
          <FormLabel component="legend">Preferred Method of Contact</FormLabel>
          <RadioGroup aria-label="preferred contact" name="preferredmethod" value={preferredMethod || "email"} onChange={(e) => setPreferredMethod(e.target.value)}>
            <FormControlLabel value="email" control={<Radio />} label="Email" />
            <FormControlLabel value="phone" control={<Radio />} label="Phone" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign='center'>
            <Button 
              id="save"
              name="save"
              variant ="contained"
              onClick={() => saveChanges()}
            >
              Save Changes
            </Button>
          </Box>
        </Grid>
        {saved && <Alert children={"Profile Saved Successfully"}/>}
      </Container>        
    </>
  );
});

export default Profile;
