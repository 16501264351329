import { useEffect, useState } from "react";

export const useFetch = (apiPath, requestCount = 1, nextToken='') => {
  const [data, setData] = useState(null);
  const [token, setToken] = useState('');
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!apiPath) return;
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      console.log(`${apiUrl}${apiPath}${nextToken}`)
      try {
        const response = await fetch(`${apiUrl}${apiPath}${nextToken}`, {

        });
        console.log(response)
        const responseData = await response.json();
        const {userGroup, token} = responseData;
        console.log(responseData)

        console.log(userGroup);
        console.log(token);

        if (responseData.status === "Unauthorized") {
          throw new Error("Unauthorized");
        }
        if(!userGroup){
          setData(responseData);
          setToken(token);
        }
        else
        setData(userGroup);
        setToken(token);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [apiPath, requestCount]);

  return { data, token, error };
};