import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import {version as app_version} from './version';

export default function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        Version {app_version} | {'Copyright © '} Olympic Ophthalmics
        {' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}