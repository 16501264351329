import React, { useEffect, useState } from "react";
import { Loading } from ".";
import { useFetch } from "../utils";
import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_REGION,
});
const cognito = new AWS.CognitoIdentityServiceProvider();

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const Manufacturers = withAuth0(() => {
  const classes = useStyles();

  const [ requestNumber, setRequestNumber ] = useState(0);

  const { data, error } = useFetch(`/manufacturers`, requestNumber);

  const [ makeRoleRequest, setRoleRequest ] = useState(null);


  useEffect(() => {
    if(!makeRoleRequest){
      return;
    }

    const fetchData = async () => {
      let newRole;
      const apiUrl = process.env.REACT_APP_API_URL;

      if (makeRoleRequest.role === "Doctor"){
        console.log("set role request: " + makeRoleRequest.role)
        newRole = "Doctor";
      }
      else if (makeRoleRequest.role === "Patient"){
        console.log("set role request: " + makeRoleRequest.role)
        newRole = "Patient";
      }
      else{
        console.log("set role request: " + makeRoleRequest.role)
        newRole = "Admin";
      }
  
      const response = await fetch(`${apiUrl}/users/${makeRoleRequest.username}/updaterole`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({userGroup: newRole}),
      });
        
      console.log(response);

      setRoleRequest(null);
      setRequestNumber(r => r + 1);
    }

    fetchData();
  }, [makeRoleRequest]);

  async function deleteAccount(username) {
    const fetchData = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      const npiResponse = await fetch(`${apiUrl}/users/${username}`, {
        method: "DELETE",
      })
      console.log(npiResponse)
      return npiResponse.json();
    }

    fetchData()
      .then(()=> {
        cognito.adminDeleteUser({
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: username,
        }).promise()
          .then(() => {
            setRequestNumber(r => r + 1);
          })
      });
  };

  const IsolatedMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
   
    return(
    <React.Fragment>
      <Button
      variant ="contained"
      aria-label="outlined primary button group"
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={event => setAnchorEl(event.currentTarget)}
      >
        <ArrowDropDownIcon />
      </Button>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient,
              role: "Doctor"
            })
        }}>Make Doctor</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient,
              role: "Patient"
            })
        }}>Make Patient</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient,
              role: "Admin"
            })
        }}>Make Admin</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            deleteAccount(props.patient);
        }}>Delete Account</MenuItem>
      </Menu>
    </React.Fragment>
    )
  }

  if ((!data && !error) || makeRoleRequest) {
    return <Loading />;
  }

  return (
    <>
      <Container className={classes.container}>
        <Typography variant="h4">Manufacturers</Typography>
        &nbsp;
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Typography variant="h5">
              Manufacturer E-mail
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h5">
              Account Created
            </Typography>
          </Grid>
            
          {data && data.sort((a, b) => a.email - b.email).map(admin => {
            return <React.Fragment key={admin.email}>
              <Grid item xs={4}>
                <Typography variant="body1">
                  {admin.email}
                </Typography>
              </Grid>
              <Grid item xs={4}>{admin.created}</Grid>
              <Grid item xs={4} style={{textAlign: 'left'}}>
                  <IsolatedMenu patient={admin.email}/>
              </Grid>
            </React.Fragment>;
          })}
        </Grid>
      </Container>
    </>
    );
  
});

export default Manufacturers;
