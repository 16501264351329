import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio, 
  Box
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useState } from "react";
import awsconfig from "../aws-exports";
import Amplify from "aws-amplify";
import Alert from './snackbar-message';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

Amplify.configure(awsconfig);

const AddPatient = withAuth0(() => {
  const classes = useStyles();
  const [patientEmail, setPatientEmail] = useState(null);
  const [patientName, setPatientName] = useState(null);
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [preferredMethod, setPreferredMethod] = useState('email');
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [saved, setSaved] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const nameErrorText = "Name is required";
  const emailErrorText = "Email is required";

  async function saveChanges() {
    setSaved(false);
    setNameError(false);
    setEmailError(false);
    if(patientName && patientEmail) {
      const apiUrl = process.env.REACT_APP_API_URL;
      const updateResponse = await fetch(`${apiUrl}/patients/${patientEmail}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: patientEmail,
          name: patientName,
          email: patientEmail,
          address: address,
          phone: phoneNumber,
          preferredContactMethod: preferredMethod,
        }),
      });
      console.log(updateResponse);
      if(updateResponse.status === 200) {
        setAlertSeverity("success");
        setAlertText("Account created successfully");
        setSaved(true);
      }
      if(updateResponse.status === 500) {
        setAlertSeverity("error");
        setAlertText("Error: An error occured trying to create this user")
        setSaved(true);
      }
      if(updateResponse.status === 403) {
        setAlertSeverity("error");
        setAlertText("Error: User already exists")
        setSaved(true);
      }
    }
    else {
      if (!patientName) {
        setNameError(true);
      }
      if (!patientEmail) {
        setEmailError(true);
      }
    }

  }

  return(
    <>
      <Container className={classes.container}>
        <Typography variant="h4">Add Patient</Typography>
        <TextField
          margin="normal"
          variant="outlined"
          required
          fullWidth
          id="sign-up-email"
          label="Patient Email"
          name="patientEmail"
          autoComplete="email"
          type="email"
          error={emailError}
          onChange={(e) => setPatientEmail(e.target.value)}
        />
        {emailError && (
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '15px' }}>{emailErrorText}</Typography>
          </Grid>
        )}
        <TextField
          margin="normal"
          variant="outlined"
          required
          fullWidth
          id="sign-up-name"
          label="Patient Name"
          name="patientName"
          autoComplete="name"
          type="name"
          error={nameError}
          onChange={(e) => setPatientName(e.target.value)}
        />
        {nameError && (
          <Grid item>
            <Typography style={{ color: 'red', fontSize: '15px' }}>{nameErrorText}</Typography>
          </Grid>
        )}
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="sign-up-address"
          label="Patient Address"
          name="patientAddress"
          autoComplete="address"
          type="address"
          onChange={(e) => setAddress(e.target.value)}
        />
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          id="sign-up-number"
          label="Patient Phone Number"
          name="patientName"
          autoComplete="phone"
          type="phone"
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <br/>
        <br/>
        <Grid item xs={12}>
          <FormLabel component="legend">Patient Preferred Method of Contact</FormLabel>
          <RadioGroup aria-label="preferred contact" name="preferredmethod" value={preferredMethod} onChange={(e) => setPreferredMethod(e.target.value)}>
            <FormControlLabel value="email" control={<Radio />} label="Email" />
            <FormControlLabel value="phone" control={<Radio />} label="Phone" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Box textAlign='center'>
            <Button 
              id="save"
              name="save"
              variant ="contained"
              onClick={() => saveChanges()}
            >
                Create Patient Account
            </Button>
          </Box>
        </Grid>
        {saved && <Alert children={alertText} severity={alertSeverity}/>}
      </Container>        
    </>
  );
});

export default AddPatient;
