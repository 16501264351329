import { withAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import 'date-fns';
import React, { useEffect, useState } from "react";
import { Loading } from ".";
import { Auth } from 'aws-amplify';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_REGION,
});
const cognito = new AWS.CognitoIdentityServiceProvider();
const DISPLAY_PAGE = 5;
let nextToken = '';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginBottom: theme.spacing(2),
    },
  };
});

const Patients = withAuth0(() => {
  const classes = useStyles();
  const [ makeRoleRequest, setRoleRequest ] = useState(null);
  const [userRole, setUserRole] = useState(null);  
  const [data, setData] = useState("");
  const [gotData, setGotData] = useState(false);
  const [NPI, setNPI] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const apiUrl = process.env.REACT_APP_API_URL;

  const modifyPageIndex = (isIncrement) => {
    console.log(nextToken);
    if (!isIncrement){
      setPageIndex(pageIndex - 1);
      return;
    } else {
      setPageIndex(pageIndex + 1);
    }
    console.log(data.length / DISPLAY_PAGE);
    console.log(pageIndex)
    if ((pageIndex + 1) >= data.length / DISPLAY_PAGE && nextToken !== ''){
      console.log('execute')
      fetchAdminData()
      .then((result)=> { 
        const {userGroup, token} = result;
        console.log(result)
        nextToken = token;
        let currData = data;
        currData = currData.concat(userGroup)
        console.log(currData)
        setData(currData)
        setGotData(true);  
    });
    }
  }
  useEffect(() => {
    let role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString();
    console.log(role)
    setUserRole(role);

    if(!makeRoleRequest){
      return;
    }

    const fetchData = async () => {
      let newRole;

      if (makeRoleRequest.role === "Doctor"){
          console.log("set role request: " + makeRoleRequest.role)
          newRole = "Doctor";
        }
      else if (makeRoleRequest.role === "Admin"){
          console.log("set role request: " + makeRoleRequest.role)
          newRole = "Admin";
      }
      else {
        console.log("set role request: " + makeRoleRequest.role)
        newRole = "Manufacturer";
    }

      const response = await fetch(`${apiUrl}/users/${makeRoleRequest.username}/updaterole`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({userGroup: newRole}),
      });
      
      console.log(response);

      setRoleRequest(null);
    }

    fetchData()
      .then(() => {
        fetchAdminData()   
          .then((result)=> {
            // setData(result)
            const {userGroup, token} = result;
            nextToken = token;
            setData(userGroup)
            setGotData(true)  
          });
      });

  }, [makeRoleRequest]);

  useEffect(() => {
    
    if(!setData){
      return;
    }
    
    let role = (Auth.user.signInUserSession.idToken.payload["cognito:groups"]).toString()
    let email = Auth.user.username;
    const fetchData = async () => {
      const npiResponse = await fetch(`${apiUrl}/doctors/${email}/npi`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      return npiResponse.json();
    }
    if(role === "Admin") {
      fetchAdminData().then((result)=> { 
        const {userGroup, token} = result;
        nextToken = token;
        setData(userGroup)
        setGotData(true)  
      });
    }
    else if(role === "Doctor") {
      fetchData().then((email_result)=> { 
        let valid_npi = email_result[0]["validNPI"] || null;
        let npi = email_result[0]["NPI"];
        fetchDoctorData(npi).then((result) => {
          if(valid_npi){
            setNPI(npi)
            setData(result)  
            // setData(result)
            setGotData(true)
          }
          else {
            setData(null)
            setGotData(true)
          }
          console.log(result)
        });
      });  
    }   
  }, [setData]);

  async function deleteAccount(username) {
    const fetchData = async () => {
      const npiResponse = await fetch(`${apiUrl}/users/${username}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      })
      console.log(npiResponse)
      return npiResponse.json();
    }

    fetchData()
      .then(()=> {
        cognito.adminDeleteUser({
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: username,
        }).promise()
          .then(() => {
            fetchAdminData()   
              .then((result)=> {
                console.log(result)
                // setData(result)
                const {userGroup, token} = result;
                nextToken = token;
                setData(userGroup)    
                setGotData(true)  
              });
          });
      });
  };

  const fetchDoctorData = async (npi) => {
    if(npi){
      const npiResponse = await fetch(`${apiUrl}/patients/${npi}/doctorpatients`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      return npiResponse.json();
    }
    return null;
  }
  
  const fetchAdminData = async () => {
    console.log(`${apiUrl}/patients/${nextToken}`)
    let patientsUrl;
    if(nextToken) {
      patientsUrl = `${apiUrl}/patients/${nextToken}`;
    }
    else {
      patientsUrl = `${apiUrl}/patients`;
    }
    console.log()
    const npiResponse = await fetch(patientsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    console.log(npiResponse)
    return npiResponse.json();
  }

  async function downloadPatients() {
    let adminRows = [['username', 'created', 'account_status', 'patientName', 'phoneNumber', 'address']];
    let doctorRows = [['username']];
    let csvContent;

    if(userRole === "Admin") {
      data.sort((a, b) => a.email - b.email).map(patient => {
        const fetchData = async () => {
          const npiResponse = await fetch(`${apiUrl}/patientprofile/${patient.username}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          })
          return npiResponse.json();
        }
        fetchData()
          .then( data => {
            adminRows.push([patient.username, patient.created, patient.status, data[0]['patientName'], data[0]['phoneNumber'], data[0]['address']])
          })
        })

      const generateFile = async () => {
        csvContent = "data:text/csv;charset=utf-8,";
        console.log(adminRows)
        console.log(adminRows[0].length)
        adminRows.forEach( arr => console.log(arr))
        adminRows.forEach( arr => {
          let row = arr.join(",");
          console.log(row);
          console.log(adminRows)

          csvContent += row + "\r\n";
        })
      }
      await generateFile().then(()=> {
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "patient_list.csv");
        document.body.appendChild(link); // Required for FF
      })
      // csvContent = "data:text/csv;charset=utf-8,";
      // console.log(adminRows)
      // adminRows.forEach( arr => {
      //   let row = arr.join(",");
      //   console.log(row);
      //   csvContent += row + "\r\n";
      // let encodedUri = encodeURI(csvContent);
      // let link = document.createElement("a");
      // link.setAttribute("href", encodedUri);
      // link.setAttribute("download", "patient_list.csv");
      // document.body.appendChild(link); // Required for FF


      }
    else {
      data.sort((a, b) => a.email - b.email).map(patient => {
        doctorRows.push([patient.username])
      })
      csvContent = "data:text/csv;charset=utf-8," + doctorRows.map(e => e.join(",")).join("\n");
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "patient_list.csv");
      document.body.appendChild(link); // Required for FF
        
      link.click();
  
    }

    // let encodedUri = encodeURI(csvContent);
    // let link = document.createElement("a");
    // link.setAttribute("href", encodedUri);
    // link.setAttribute("download", "patient_list.csv");
    // document.body.appendChild(link); // Required for FF
      
    // link.click();
  };

  
  const IsolatedMenu = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
   
    return(
    <React.Fragment>
      <Button
      variant ="contained"
      aria-label="outlined primary button group"
      aria-controls="simple-menu"
      aria-haspopup="true"
      onClick={event => setAnchorEl(event.currentTarget)}
      >
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >       
       <MenuItem onClick={() => {
            setAnchorEl(null); 
            window.location.href = `/patientprofile/${props.patient.username}`;
        }}>View Patient Profile</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null); 
            window.location.href = `/prescriptions/${props.patient.username}`;
        }}>View Prescriptions</MenuItem>
        <MenuItem onClick={() => {
            setAnchorEl(null);
            window.location.href = `/sessions/${props.patient.username}`;
        }}>View Sessions</MenuItem>
        {userRole === "Admin" && (<MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient.username,
              role: "Admin"
            })
        }}>Make Admin</MenuItem>)}
        {userRole === "Admin" && (<MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient.username,
              role: "Doctor"
            })
        }}>Make Doctor</MenuItem>)}
        {userRole === "Admin" && (<MenuItem onClick={() => {
            setAnchorEl(null);
            setRoleRequest({
              username: props.patient.username,
              role: "Manufacturer"
            })
        }}>Make Manufacturer</MenuItem>)}
        <MenuItem onClick={() => {
            setAnchorEl(null);
            deleteAccount(props.patient.username);
        }}>Delete Account</MenuItem>
        </Menu>
    </React.Fragment>
    )
  }

  if ((!gotData) || makeRoleRequest) {
    return <Loading />;
  }

  if(userRole === "Admin") { //If user is a doctor, display on their patients and the device data
    let dataCount = 1;
    let dataSubset = [];
    if (data){
      console.log(data);
      if ((data.length / DISPLAY_PAGE) != 0){
        dataCount = Math.floor((data.length / DISPLAY_PAGE)) + 1;
      } else {
        dataCount = Math.floor((data.length / DISPLAY_PAGE)) + 1;
      }
      dataSubset = data.slice(pageIndex* DISPLAY_PAGE, (pageIndex*DISPLAY_PAGE) + DISPLAY_PAGE)
    }
    return (
      <>
        <Container className={classes.container}>
          &nbsp;
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography variant="h4">Patients</Typography>
            </Grid>
            <Grid container justify='flex-end' xs={7}>
              <Button
                variant ="contained"
                aria-haspopup="true"
                justify="right"
                onClick={() => downloadPatients()}
              >
                Download Patient List
              </Button>
            </Grid>
            <Grid item xs={1} style={{textAlign: 'right'}}></Grid>
            <Grid item xs={4}>
              <Typography variant="h5">
                Patient E-mail
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h5">
                Patient Name
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h5">
                Account Created
              </Typography>
            </Grid>  
            <Grid item xs={2}>
              <Typography variant="h5">
                Account Status
              </Typography>
            </Grid>

            {data && dataSubset.sort((a, b) => a.email - b.email).map(patient => {
              return <React.Fragment key={patient.email}>
                <Grid item xs={4}>
                  <Typography variant="body1">
                    {patient.email}
                  </Typography>
                </Grid>
                <Grid item xs={3}>{patient.name}</Grid>
                <Grid item xs={2}>{patient.created}</Grid>
                <Grid item xs={2}>{patient.status}</Grid>
                
                <Grid item xs={1} style={{textAlign: 'right'}}>
                  <IsolatedMenu patient={patient}/>
                </Grid>              
              </React.Fragment>;
            })}
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <Button onClick={() => modifyPageIndex(false)} disabled={pageIndex === 0}><ArrowBackIosIcon/></Button>
              <Button onClick={() => modifyPageIndex(true)} disabled={nextToken == '' && ((pageIndex+1)*DISPLAY_PAGE >= data.length)}><ArrowForwardIosIcon/></Button>
            </Grid>
          </Grid>        
        </Container>


      </>
    );
  }
  else{ //Doctors
    return (
      <>
        <Container className={classes.container}>
          &nbsp;
          <Grid container spacing={1}>    
          <Grid item xs={4}>
              <Typography variant="h4">Patients</Typography>
            </Grid>
            <Grid container justify='flex-end' xs={8}>
              <Button
                variant ="contained"
                aria-haspopup="true"
                justify="right"
                onClick={() => downloadPatients()}
              >
                Download Patient List
              </Button>
            </Grid> 

            {data && data.sort((a, b) => a.username - b.username).map(patient => {
              return <React.Fragment key={patient.emausernameil}>
                <Grid item xs={6}>
                  <Typography variant="body1" style={{
                    marginTop: '20px'
                  }}>
                    {patient.username}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right', marginTop: '20px'}}> 
                  <IsolatedMenu patient={patient}/>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>    
              </React.Fragment>;
            })}
          </Grid>
        </Container>
      </>
    );
  }
});

export default Patients;
